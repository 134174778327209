import SideBarFooter from "@components/sidebar/side-bar-footer";
import SideBarPublisher from "@components/sidebar/side-bar-publishers";
import SideBarTrendingNewsStacks from "@components/sidebar/side-bar-trending-news-stacks";
import SideBarQrContainer from "./side-bar-qr-container";
import SideBarTopics from "./side-bar-topics";
import { useWindowWidth } from "@react-hook/window-size";
import { Trans } from "@lingui/macro";
import { useEffect, useState } from "react";
import { CommentDetailsFragment } from "@graphql/generated/graphql";
import CommentsBox from "@components/comments/comments-box";

interface ISideBarProps {
  showTrending?: boolean;
  showQr?: boolean;
  showTopics?: boolean;
  showPublisher?: boolean;
  showFooter?: boolean;
}

const SideBar: React.FunctionComponent<ISideBarProps> = ({
  showTrending = true,
  showQr = true,
  showTopics = true,
  showPublisher = true,
  showFooter = true,
}) => {
  const width = useWindowWidth();
  const [hideSidebar, setHideSideBar] = useState(true);

  useEffect(() => {
    if (width < 1024) {
      setHideSideBar(true);
    } else {
      setHideSideBar(false);
    }
  }, [width]);

  return hideSidebar ? (
    <div className="hidden lg:w-80"></div>
  ) : (
    <div className="mt-6 w-80 space-y-10">
      {showQr && (
        <SideBarQrContainer
          title={<Trans>It's better on the app</Trans>}
          label={<Trans>👈 Scan the QR code to download</Trans>}
        />
      )}
      {showTopics && <SideBarTopics />}
      {showPublisher && <SideBarPublisher />}
      {showTrending && <SideBarTrendingNewsStacks />}
      {showFooter && <SideBarFooter />}
    </div>
  );
};

export default SideBar;
