import Link from "next/link";
import { Trans } from "@lingui/macro";
import { ArrowRightIcon } from "@heroicons/react/20/solid";
import { DialogName, triggerDialog } from "@components/core/dialog";
import Label from "@components/shared/label";

export interface ISideBarTopicsProps {
  id?: number;
  key: String;
  label: JSX.Element | String;
  emoji: String;
  newStatus?: boolean;
  hot?: boolean;
}

export const topCategories: ISideBarTopicsProps[] = [
  // {
  //   key: "GE15",
  //   label: <Trans>GE15</Trans>,
  //   emoji: "🗳",
  // },
  {
    key: "Trending",
    label: <Trans>Trending</Trans>,
    emoji: "🔥",
  },
  {
    key: "Good_News",
    label: <Trans>Good News 🎉</Trans>,
    emoji: "",
  },
  {
    key: "News",
    label: <Trans>News</Trans>,
    emoji: "☕",
  },
  {
    key: "World",
    label: <Trans>World</Trans>,
    emoji: "🌎",
  },
  {
    key: "Politics",
    label: <Trans>Politics</Trans>,
    emoji: "♟️",
  },
  {
    key: "Lifestyle",
    label: <Trans>Lifestyle</Trans>,
    emoji: "🏝️",
  },
  {
    key: "Tech",
    label: <Trans>Tech</Trans>,
    emoji: "📱",
  },
  {
    key: "Sports",
    label: <Trans>Sports</Trans>,
    emoji: "🏸",
  },
];

const SideBarTopics: React.FunctionComponent = ({}) => {
  const openMoreTopicsDialog = (event) => {
    event.preventDefault();
    triggerDialog(DialogName.moreTopics);
  };

  return (
    <div className="flex flex-col space-y-6">
      <p className="font-poppins text-lg font-bold text-text-darkest">
        <Trans>Topics to check out</Trans>
      </p>
      <div className="flex flex-wrap">
        {topCategories.map((topic) => (
          <TopicsTab key={topic.key as React.Key} topic={topic} />
        ))}
      </div>
      <div className="flex flex-row items-center space-x-2">
        <div
          onClick={(event) => {
            openMoreTopicsDialog(event);
          }}
          className="cursor-pointer"
        >
          <Trans>
            <p
              className="font-sen text-sm text-primary2 underline"
              id="more-topics"
            >
              More topics
            </p>
          </Trans>
        </div>
        <ArrowRightIcon className="h-4 w-4 text-primary2" />
      </div>
    </div>
  );
};

interface ITopicTabProps {
  topic: ISideBarTopicsProps;
}

const TopicsTab: React.FunctionComponent<ITopicTabProps> = ({
  topic: { key, emoji, newStatus, hot, label },
}) => {
  return (
    <Link href={`/news?topic=${key}`}>
      <a>
        <div className="mb-2 mr-2 flex flex-row items-center space-x-1 rounded-2xl bg-secondary px-4 py-2">
          <p className="text-center font-sen text-sm text-primary2">
            {label} {emoji}
          </p>
          {newStatus ? (
            <Label labelName="new" />
          ) : (
            hot && <Label labelName="hot" />
          )}
        </div>
      </a>
    </Link>
  );
};

export default SideBarTopics;
