import Link from "next/link";
import Image from "next/image";
import Bluetick from "@public/blue-tick.svg";
import { useCategoryListQuery } from "@graphql/generated/graphql";
import { revertFormatUrl } from "@lib/formatUrl";
import { useRouter } from "next/router";
import { useEffect, useMemo } from "react";
import { Trans } from "@lingui/macro";
import { errorToastPopup } from "@lib/errorToastPopup";
import Label from "@components/shared/label";

interface ISideBarTrendingNewsStacksProps {
  showTitle?: boolean;
}

const SideBarTrendingNewsStacks: React.FunctionComponent<
  ISideBarTrendingNewsStacksProps
> = ({ showTitle = true }) => {
  const {
    query: { name },
    pathname,
    locale,
  } = useRouter();

  const { data, loading, refetch, error } = useCategoryListQuery({
    variables: {
      explore: true,
      languageCode: locale,
    },
  });

  const trendingNewsStack = useMemo(() => {
    if (loading) {
      return [];
    }

    if (pathname === "/news-stack/[name]") {
      const title = revertFormatUrl(name as string);

      return data.allCategories.filter((category) => category.key !== title);
    } else {
      return data.allCategories;
    }
  }, [pathname, data, name, loading]);

  useEffect(() => {
    refetch({ languageCode: locale });
  }, [locale, refetch]);

  useEffect(() => {
    if (error) {
      errorToastPopup(error);
    }
  }, [error]);

  if (loading || data.allCategories.length <= 0 || error) {
    return <></>;
  }

  return (
    <div className="">
      {showTitle && (
        <p className="pb-4 font-poppins text-lg font-bold text-text-darkest">
          <Trans>Trending News Stacks</Trans>
        </p>
      )}

      <div className="space-y-4" id="trending-news-stack">
        {trendingNewsStack.map(
          ({
            id,
            key,
            slug,
            imageUrl,
            exploreName,
            hot,
            updated,
            exclusive,
            author,
          }) => (
            <div key={id} className="rounded-lg bg-off-white">
              <Link href={`/news-stack/${slug}`} passHref>
                <a>
                  <div className="flex flex-row items-center space-x-4">
                    <div className="relative h-[130px] w-[100px] shrink-0">
                      <Image
                        className="rounded-l-lg"
                        src={imageUrl}
                        alt={key}
                        layout="fill"
                        objectFit="cover"
                      />
                    </div>
                    <div className="py-2 px-1">
                      {exclusive ? (
                        <div className="py-1">
                          <Label labelName="exclusive" />
                        </div>
                      ) : updated ? (
                        <div className="py-1">
                          <Label labelName="updated" />
                        </div>
                      ) : (
                        hot && (
                          <div className="py-1">
                            <Label labelName="hot" />
                          </div>
                        )
                      )}
                      <p className="pr-2 font-poppins font-bold leading-tight text-text-darkest">
                        {exploreName}
                      </p>
                      {author && (
                        <div>
                          <p className="inline-flex font-sen text-sm text-text-dark">
                            {author.name}
                            {author.verified && (
                              <div className="relative ml-1 mt-0.5 inline-flex h-4 w-4">
                                <Image
                                  src={Bluetick}
                                  alt="blue-tick"
                                  layout="fill"
                                  objectFit="cover"
                                />
                              </div>
                            )}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </a>
              </Link>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default SideBarTrendingNewsStacks;
