import useOneLinkUrl from "hooks/useOneLinkUrl";
import QRCode from "react-qr-code";

interface ISideBarQrContainerProps {
  title?: JSX.Element | string;
  label?: JSX.Element | string;
}

const SideBarQrContainer: React.FunctionComponent<ISideBarQrContainerProps> = ({
  title,
  label,
}) => {
  const url = useOneLinkUrl("https://link.luminews.my/ewC9/websidebar");

  return (
    <div
      className="flex flex-col space-y-4 rounded-lg bg-white px-4 py-4"
      style={{
        filter: "drop-shadow(1px 1px 8px rgba(147, 154, 188, 0.4)",
      }}
    >
      <p className="text-center font-poppins text-xl font-bold">{title}</p>
      <div className="flex flex-row items-center rounded-lg bg-off-white px-2 py-3">
        <QRCode value={url} size={56} />
        <p className="ml-4 flex-1 text-center font-poppins font-bold text-text-darkest">
          {label}
        </p>
      </div>
    </div>
  );
};

export default SideBarQrContainer;
