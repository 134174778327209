export const topPublisherList = {
  en: [
    {
      __typename: "NewsPublisher",
      id: "3",
      name: "New Straits Times",
      slug: "New_Straits_Times",
      iconUrl: "https://image.luminews.my/2bdlab3q4ztmhga0m31k4he0xns0",
    },
    {
      __typename: "NewsPublisher",
      id: "18",
      name: "FMT News",
      slug: "FMT_News",
      iconUrl: "https://image.luminews.my/u2op38i1jt1zk587dounod4t9wda",
    },
    {
      __typename: "NewsPublisher",
      id: "1",
      name: "SAYS",
      slug: "SAYS",
      iconUrl: "https://image.luminews.my/ka0k8qfpbza9l2b1zr6pnveed7qe",
    },
    {
      __typename: "NewsPublisher",
      id: "83",
      name: "The Vibes",
      slug: "The_Vibes",
      iconUrl: "https://image.luminews.my/6s8zdom3g1ybn8psl194bno7sd5n",
    },
    {
      __typename: "NewsPublisher",
      id: "21",
      name: "The Rakyat Post",
      slug: "The_Rakyat_Post",
      iconUrl: "https://image.luminews.my/fyd6tnm764q1b7w39z2fh10yl8nu",
    },
  ],
  ms: [
    {
      __typename: "NewsPublisher",
      id: "4",
      name: "Berita Harian",
      slug: "Berita_Harian",
      iconUrl: "https://image.luminews.my/16fgqipiz589ekhvwdxbbj58misy",
    },
    {
      __typename: "NewsPublisher",
      id: "5",
      name: "Harian Metro",
      slug: "Harian_Metro",
      iconUrl: "https://image.luminews.my/ccwxnupuwe7q8x3orn2zvdb9i1tt",
    },
    {
      __typename: "NewsPublisher",
      id: "6",
      name: "Utusan Malaysia",
      slug: "Utusan_Malaysia",
      iconUrl: "https://image.luminews.my/yl8bn7mhgy3txpy7qbspmnwctfdw",
    },
    {
      __typename: "NewsPublisher",
      id: "87",
      name: "Sinar Harian",
      slug: "Sinar_Harian",
      iconUrl: "https://image.luminews.my/v6r5clxw41qvqntsmzvmwa3cka0p",
    },
    {
      __typename: "NewsPublisher",
      id: "122",
      name: "Buletin TV3",
      slug: "Buletin_TV3",
      iconUrl: "https://image.luminews.my/04a1txrfgr2qqltsgzsjj5wa83ls",
    },
  ],
  zh: [
    {
      __typename: "NewsPublisher",
      id: "84",
      name: "東方日報",
      slug: "東方日報",
      iconUrl: "https://image.luminews.my/s091a83b22ijgkdvu0w21bdb8vyu",
    },
    {
      __typename: "NewsPublisher",
      id: "31",
      name: "Bernama 中文",
      slug: "Bernama_中文",
      iconUrl: "https://image.luminews.my/fj0zizhjazjk6w758h3v085ix8kh",
    },
    {
      __typename: "NewsPublisher",
      id: "123",
      name: "八度空间华语新闻",
      slug: "八度空间华语新闻",
      iconUrl: "https://image.luminews.my/0ko4l9leqgbi2q5d145tskrpgovo",
    },
    {
      __typename: "NewsPublisher",
      id: "7",
      name: "ViralCham",
      slug: "ViralCham",
      iconUrl: "https://image.luminews.my/i1d45t3qn3hnpg6ena90q3lm461y",
    },
    {
      __typename: "NewsPublisher",
      id: "8",
      name: "Rojaklah",
      slug: "Rojaklah",
      iconUrl: "https://image.luminews.my/03rhxwgt561ads99sx31gc6oktn5",
    },
  ],
};
