import Image from "next/image";
import Link from "next/link";
import { Trans } from "@lingui/macro";
import { ArrowRightIcon } from "@heroicons/react/20/solid";
import { useRouter } from "next/router";
import { topPublisherList } from "./side-bar-publisher-list";

const SideBarPublisher: React.FunctionComponent = () => {
  const { locale } = useRouter();

  return (
    <div className="space-y-4">
      <p className="font-poppins text-lg font-bold text-text-darkest">
        <Trans>Top 5 publishers on Lumi</Trans>
      </p>
      {topPublisherList[locale].map(({ id, iconUrl, name, slug }) => (
        <div key={id}>
          <Link href={`/publishers/${slug}`}>
            <a>
              <div className="flex flex-row items-center space-x-3">
                <div
                  className="relative h-[40px] w-[40px]"
                  style={{
                    filter:
                      "drop-shadow(0px 4px 16px rgba(147, 154, 188, 0.4))",
                  }}
                >
                  <Image
                    className="rounded-lg"
                    src={iconUrl}
                    alt={name}
                    layout="fill"
                    objectFit="cover"
                  />
                </div>
                <p className="font-sen text-sm text-text-dark">{name}</p>
              </div>
            </a>
          </Link>
        </div>
      ))}

      <Link href={`/publishers?language=${locale}`}>
        <a>
          <div className="flex flex-row items-center space-x-2 pt-8">
            <p
              className="font-sen text-sm text-primary2 underline"
              id="more-publishers"
            >
              <Trans>More publishers</Trans>
            </p>
            <ArrowRightIcon
              className="h-4 w-4 text-primary2"
              aria-hidden="true"
            />
          </div>
        </a>
      </Link>
    </div>
  );
};

export default SideBarPublisher;
