import {
  FaFacebookSquare,
  FaTwitterSquare,
  FaInstagramSquare,
  FaDiscord,
} from "react-icons/fa";
import {
  sharedLinks,
  selectedIndexForSidebar,
  redirectedBrowserTab,
  SocialMediaIcons,
} from "@components/layout/shared/link-props";
import { useRouter } from "next/router";
import BlueTikTokSvg from "@public/blue-tiktok.svg";
import Image from "next/image";

const SideBarFooter: React.FunctionComponent = () => {
  const currentYear = new Date().getFullYear();
  const router = useRouter();
  const { pathname, locale, asPath } = router;

  // To open a new tab if logo or link of sidebar is clicked on subdomain pages
  const showNewTab = pathname.startsWith("/_subdomain");

  const selectedLocale =
    (locale || router.defaultLocale) === "en" ? "" : locale;

  return (
    <div className="flex flex-col">
      <div className="mb-6 flex items-start space-x-4 text-primary4">
        <SocialMediaIcons tiktokColor="blue" />
      </div>

      <div className="mb-6 flex flex-wrap items-center">
        {sharedLinks.slice(0, selectedIndexForSidebar + 1).map((link) =>
          link.external ? (
            <a
              href={link.withLocale ? link.href + selectedLocale : link.href}
              key={link.href}
              target="_blank"
              rel="noreferrer"
            >
              <p className="mr-4 mb-4 font-sen text-xs text-text-medium">
                {link.title}
              </p>
            </a>
          ) : (
            <div key={link.href}>
              {redirectedBrowserTab(
                link,
                asPath,
                showNewTab,
                locale,
                "topSidebar"
              )}
            </div>
          )
        )}
      </div>

      <div className="space-y-2">
        <p className="font-sen text-xs text-disabled">
          © Copyright Lumi {currentYear}
        </p>
        <p className="font-sen text-xs text-disabled">
          Lumi Global Sdn Bhd (1407575-K)
        </p>
        {sharedLinks
          .slice(selectedIndexForSidebar + 1, sharedLinks.length)
          .map((link) => (
            <div key={link.href}>
              {redirectedBrowserTab(
                link,
                pathname,
                showNewTab,
                locale,
                "btmSidebar"
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default SideBarFooter;
